import {
  TColor,
  TVariationOption,
  IDetailedOrder,
  IShipment,
  IShortOrder,
  IEfficiency,
  ICancelReasonDefaultOptions,
  TPhoto,
} from './types';
import { IBrand } from './types/brand';
import { IDashboard } from './types/dasboard';
import { IManager } from './types/employee';
import { EEmployeeRoles } from './types/employee/enums';
import {
  ActiveStatusEnum,
  EStatuses,
  GoogleMerchantsDestinations,
  EResponsibles,
} from './types/enums';
import {
  EPaymentCancelledStatuses,
  EDisputeStatuses,
  EToDoStatuses,
  EReturnStatusEnum,
} from './types/order/enums';
import { IProduct, IVariation, ICommonProduct } from './types/product';
import { IPromocode } from './types/promocode';
import { PromoTypeEnum } from './types/promocode/enums';
import { IPurchase } from './types/purchase';
import { EReturnReasons } from './types/return/enums';
import { IReturnShipment } from './types/return-shipment';
import { EReturnShipmentStatuses } from './types/return-shipment/enums';
import { EShipmentReasons } from './types/shipment/enums';
import { ISubcategory } from './types/subcategory';

export const variationStatuses = ['ACTIVE', 'DRAFT', 'ARCHIVE'];

export const MAX_PRODUCT_PHOTOS_LIMIT = 30;

export const variationStatusesOptions = variationStatuses.map((status) => ({
  value: status,
  label: status,
}));

export const defaultImage =
  'https://asp24.ru/images/detailed/13/CRS328-24P-4S+RM_1200.png';

export const defaultPhoto: TPhoto = { id: 0, key: '0', url: defaultImage };

export const defaultBrandEntity: IBrand = {
  id: 0,
  title: '',
  slug: '',
  photo: null,
  main: false,
  displayPriority: 1,
  description: '',
  status: EStatuses.ARCHIVE,
  subcategoriesCount: { total: 0, active: 0 },
  productsCount: { total: 0, active: 0 },
  metaTitle: null,
  metaDescription: null,
};

export const defaultSubcategoryEntity: ISubcategory = {
  id: 0,
  title: '',
  photo: null,
  brandId: 0,
  brand: defaultBrandEntity,
  description: '',
  status: EStatuses.ARCHIVE,
  productsCount: { total: 0, active: 0 },
  googleMerchantsDestinations: [],
  metaTitle: null,
  metaDescription: null,
};

export const defaultProductEntity: IProduct = {
  id: 0,
  title: '',
  description: '',
  price: 0,
  discount: 0,
  order_link: [],
  googleMerchantsDestinations: [],
  subcategoryId: 0,
  mainPhotoId: 0,
  subcategory: defaultSubcategoryEntity,
  variations: [],
  slug: '',
  type: '',
  photos: [],
  placement: '',
  material: '',
  status: 'ACTIVE',
  color: '',
  sku: '',
  barcode: '',
  metaTitle: '',
  metaDescription: '',
  markdownSections: [],
  variationsObject: {
    color: [],
    size: [],
    material: [],
    placement: [],
    style: [],
  },
};

export const defaultTableProductEntity: ICommonProduct = {
  id: 0,
  title: '',
  description: '',
  price: 0,
  discount: 0,
  order_link: [],
  googleMerchantsDestinations: [],
  subcategoryId: 0,
  mainPhotoId: 0,
  subcategory: defaultSubcategoryEntity,
  slug: '',
  type: '',
  photos: [],
  placement: '',
  material: '',
  status: 'ACTIVE',
  color: '',
  sku: '',
  barcode: '',
  metaTitle: '',
  metaDescription: '',
};

export const defaultVariationEntity: IVariation = {
  content: {
    variationId: 0,
    mainPhotoId: 0,
    id: 0,
    barcode: '',
    color: '',
    size: '',
    // description: '', at the request of clients, we have temporarily hidden this information
    discount: 0,
    material: '',
    type: '',
    order_link: [],
    photos: null,
    placement: '',
    price: 0,
    productId: 0,
    sku: '',
    style: '',
    status: 'ACTIVE',
    photoIds: [],
    totalPrice: 0,
  },
  id: 0,
  product: defaultProductEntity,
  productId: 0,
};

export const defaultPurchaseEntity: IPurchase = {
  id: 0,
  image: defaultImage,
  title: '----',
  variation: defaultVariationEntity,
  price: 0,
  sku: '0',
  orderLink: [],
  quantity: 1,
  discount: 0,
  totalPrice: 0,
  variationId: 0,
  shipmentReasons: [],
  refund: 0,
  sum: 0,
  productKit: null,
  adjustedPrice: 0,
};

export const defaultEfficiencyEntity: IEfficiency = {
  fees: 0,
  margin: 0,
  marginPercent: 0,
  purchase: 0,
  shipmentAmount: 0,
  shipping: 0,
  total: 0,
};

export const defaultManagerEntity: IManager = {
  id: 0,
  email: '',
  firstName: '',
  lastName: '',
  role: EEmployeeRoles.MANAGER,
};

export const defaultShipmentEntity: IShipment = {
  id: 0,
  comment: {
    id: 0,
    returnShipmentId: 0,
    shipmentId: 0,
    value: '',
  },
  costOfPurchase: 0,
  costOfShipping: 0,
  createdAt: '',
  reason: '' as EShipmentReasons,
  supplier: {
    id: 0,
    name: '',
  },
  supId: '',
  trackingNumber: '',
  trackingStatus: '',
  trackingDate: '',
  updatedAt: '',
  items: [],
};

export const defaultAddressEntity = {
  country: '',
  address: '',
  state: '',
  city: '',
  postalCode: '',
  firstName: '',
  lastName: '',
};

export const defaultUserEntity = {
  createdAt: '',
  dateOfBirth: '',
  email: '',
  firstName: '',
  id: '',
  inactiveSince: '',
  lastName: '',
  updatedAt: '',
  phone: '',
  billingAddress: {
    country: '',
    address: '',
    state: '',
    city: '',
    postalCode: '',
    firstName: '',
    lastName: '',
  },
  shippingAddress: {
    country: '',
    address: '',
    state: '',
    city: '',
    postalCode: '',
    firstName: '',
    lastName: '',
  },
};

export const defaultUserDataEntity = {
  billingAddress: defaultAddressEntity,
  email: '',
  firstName: '',
  lastName: '',
  shippingAddress: defaultAddressEntity,
  phone: '',
};

export const defaultOrderEntity: IShortOrder = {
  createdAt: '',
  id: 0,
  publicId: 0,
  manager: defaultManagerEntity,
  buyer: '',
  delivery: null,
  dispute: '',
  efficiency: defaultEfficiencyEntity,
  payment: '',
  purchasing: '',
  refund: [],
  reshipments: [],
  return: {} as EReturnStatusEnum,
  sending: '',
  supplier: [],
  todo: [],
  cost: 0,
  total: 0,
  totalItemFinal: 0,
  totalPriceFinal: 0,
  comment: null,
};

export const defaultDetailedOrderEntity: IDetailedOrder = {
  createdAt: '',
  id: 0,
  publicId: 0,
  supplier: [],
  buyer: '',
  cardBrand: '',
  cardLast4: '',
  appliedPromocode: null,
  efficiency: defaultEfficiencyEntity,
  delivery: null,
  dispute: EDisputeStatuses.DISPUTE_EMPTY,
  payment: '',
  purchasing: '',
  refund: [],
  return: {} as EReturnStatusEnum,
  sending: '',
  todo: [],
  refunded: 0,
  maxSumToRefund: 0,
  overpayment: 0,
  amountPaid: 0,
  itemStats: [],
  items: [],
  shipments: [],
  reshipments: [],
  returnShipments: [],
  promoDiscount: 0,
  discount: 0,
  kitDiscount: 0,
  shipping: 0,
  shippingFinal: 0,
  total: 0,
  totalItem: 0,
  totalItemFinal: 0,
  totalPrice: 0,
  totalPriceFinal: 0,
  user: defaultUserEntity,
  userData: defaultUserDataEntity,
};

export const defaultReturnShipmentEntity: IReturnShipment = {
  id: 0,
  status: EReturnShipmentStatuses.RETURN_STANDARD_RETURN_FINISHED,
  cancelled: false,
  comment: {
    id: 0,
    returnShipmentId: 0,
    shipmentId: 0,
    value: '',
  },
  createdAt: '',
  items: [],
  order: defaultDetailedOrderEntity,
  reason: '',
  resolved: false,
  supplier: null,
  responsible: '',
  trackingDate: '',
  trackingNumber: '',
  costOfReturn: 0,
  costOfGoods: 0,
  updatedAt: '',
};

export const defaultPromocodeEntity: IPromocode = {
  id: 0,
  discount: 0,
  purchaseSum: 0,
  promocode: '',
  promoType: null,
  isPrimary: false,
  isActive: false,
  units: '',
  isLimited: false,
  maxUses: 0,
  uses: 0,
  createdAt: '',
  updatedAt: '',
  usesLeft: 0,
};

export const defaultDashboardEntity: IDashboard = {
  totalPaid: 0,
  totalCostOfPurchase: 0,
  totalCostOfShipping: 0,
  totalFees: 0,
  totalMargin: 0,
  amountOfOrders: 0,
  amountOfShipments: 0,
  amountOfCustomers: 0,
};

export const imgDeafaultStyle = {
  cursor: 'pointer',
  height: 'auto',
  width: 'auto',
  maxWidth: '96px',
  maxHeight: '96px',
};

export const defaultOptions: TVariationOption[] = [
  {
    label: 'Size',
    value: 'size',
  },
  {
    label: 'Color',
    value: 'color',
  },
  {
    label: 'Material',
    value: 'material',
  },
  {
    label: 'Placement',
    value: 'placement',
  },
  {
    label: 'Style',
    value: 'style',
  },
];

export const colors: TColor[] = [
  { color: 'White', hex: '#D8D8D8' },
  { color: 'Black matte', hex: '#000000' },
  {
    color: 'Black glossy',
    hex: 'linear-gradient(67.07deg, #000000 13.6%, #898989 42.77%, #898989 42.78%, #898989 52.5%, #000000 85.41%)',
  },
  { color: 'Gray', hex: '#808080' },
  { color: 'Fuchsia', hex: '#FF00FF' },
  { color: 'Purple', hex: '#800080' },
  { color: 'Maroon', hex: '#800000' },
  { color: 'Red', hex: '#FF0000' },
  { color: 'Yellow', hex: '#FFFF00' },
  { color: 'Lyme', hex: '#00FF00' },
  { color: 'Green', hex: '#008000' },
  { color: 'Olive', hex: '#808000' },
  { color: 'Teal', hex: '#008080' },
  { color: 'Navy', hex: '#000080' },
  { color: 'Blue', hex: '#0000FF' },
  { color: 'Aqua', hex: '#00FFFF' },
  { color: 'Orange', hex: '#FF9900' },
  { color: 'Brown', hex: '#4F3E13' },
  { color: 'Silver', hex: '#D9D9D9' },
  { color: 'Gold', hex: '#F1CE4F' },
  { color: 'Bronze', hex: '#C98B53' },
  { color: 'Chrome', hex: '#5F5F5F' },
];

export const nonlatinicRule = {
  pattern: new RegExp(
    /^([a-z0-9 .,`@#$§%^&*{}|()-_=+±'"!?:/~_]|\s|\p{S}|\p{P})+$/i,
  ),
  message: 'Field does not accept non-latinic characters',
};

export const mockCommentsListData = [
  {
    id: 1,
    author: 'Zebadiah Popley',
    createdAt: '2021-02-05 08:28:36',
    comment: 'A design system for enterprise-level products...',
  },
  {
    id: 1,
    author: 'Clarisse Flaune',
    createdAt: '2021-02-05 08:28:36',
    comment: 'A design system for enterprise-level products...',
  },
  {
    id: 1,
    author: 'Denys Wilacot',
    createdAt: '2021-02-05 08:28:36',
    comment: 'A design system for enterprise-level products...',
  },
];

export const shipmentReasonDefaultOptions = [
  { value: EShipmentReasons.PRIMARY, label: 'Primary' },
  { value: EShipmentReasons.PARCEL_LOST, label: 'Parcel lost' },
  { value: EShipmentReasons.ITEM_DAMAGED, label: 'Item damaged' },
  { value: EShipmentReasons.ITEM_DID_NOT_FIT, label: 'Item did not fit' },
  { value: EShipmentReasons.PARCEL_RETURNED, label: 'Parcel returned' },
  { value: EShipmentReasons.WRONG_ITEM, label: 'Wrong item' },
];

export const returnReasonDefaultOptions = [
  { value: EReturnReasons.ITEM_DAMAGED, label: 'Item damaged' },
  {
    value: EReturnReasons.DOES_NOT_MEET_EXPECTATIONS,
    label: 'Does not meet expectations',
  },
  { value: EReturnReasons.ITEM_DID_NOT_FIT, label: 'Item did not fit' },
  { value: EReturnReasons.WRONG_ITEM, label: 'Wrong item' },
];

export const responsibleDefaultOptions = [
  { value: EResponsibles.CUSTOMER, label: 'Customer' },
  {
    value: EResponsibles.SUPPLIER,
    label: 'Supplier',
  },
  { value: EResponsibles.DELIVERY_COMPANY, label: 'Delivery company' },
];

export const reasonDefaultOptions = [
  { value: 'REFUND_REMOVING_ITEM', label: 'Removing item' },
  { value: 'REFUND_RETURN_FINISHED', label: 'Return finished' },
  { value: 'REFUND_ITEM_DAMAGED', label: 'Item damaged' },
  { value: 'REFUND_PARCEL_LOST', label: 'Parcel lost' },
  { value: 'REFUND_WRONG_ITEM', label: 'Wrong item' },
  { value: 'REFUND_STRIPE_DISPUTE', label: 'Stripe dispute' },
  { value: 'REFUND_PARCEL_DELAYED', label: 'Parcel delayed' },
  { value: 'REFUND_BUYER_THREATS', label: 'Buyer threats' },
  { value: 'REFUND_REMOVING_SHIPPING_COST', label: 'Removing shipping cost' },
  { value: 'REFUND_PARCEL_RETURNED', label: 'Parcel returned' },
];

export const disputeOptions = [
  {
    value: EDisputeStatuses.DISPUTE_EMPTY,
    label: 'Empty',
  },
  {
    value: EDisputeStatuses.DISPUTE_STRIPE_DISPUTE_STARTED,
    label: 'Stripe dispute started',
  },
  {
    value: EDisputeStatuses.DISPUTE_STRIPE_DISPUTE_REVIEW,
    label: 'Stripe dispute review',
  },
  {
    value: EDisputeStatuses.DISPUTE_STRIPE_DISPUTE_WON,
    label: 'Stripe dispute won',
  },
  {
    value: EDisputeStatuses.DISPUTE_STRIPE_DISPUTE_LOST,
    label: 'Stripe dispute lost',
  },
];

export const todoOptions = [
  {
    value: EToDoStatuses.TODO_SPECIFY_ADDRESS,
    label: 'Specify address',
  },
  {
    value: EToDoStatuses.TODO_REPLACEMENT_REQUEST,
    label: 'Replacement request',
  },
  {
    value: EToDoStatuses.TODO_LISTING_REQUEST,
    label: 'Listing request',
  },
  {
    value: EToDoStatuses.TODO_ORDER_SPECIFICATION,
    label: 'Order specification',
  },
  {
    value: EToDoStatuses.TODO_REQUEST_AN_INVOICE,
    label: 'Request an invoice',
  },
];

export const cancelReasonDefaultOptions: ICancelReasonDefaultOptions[] = [
  {
    value: EPaymentCancelledStatuses.PAYMENT_CANCELLED_FRAUDULENT_ORDER,
    label: 'Fraudulent order',
  },
  {
    value: EPaymentCancelledStatuses.PAYMENT_CANCELLED_BY_CUSTOMER,
    label: 'By customer',
  },
  {
    value: EPaymentCancelledStatuses.PAYMENT_CANCELLED_OUT_OF_STOCK,
    label: 'Out of stock',
  },
  {
    value: EPaymentCancelledStatuses.PAYMENT_CANCELLED_UNABLE_TO_SEND,
    label: 'Unable to send',
  },
];

export const searchTypeOptions = [
  { value: 'BUYER_NAME', label: 'Name' },
  { value: 'BUYER_EMAIL', label: 'Email' },
  { value: 'ORDER_NUMBER', label: 'Order #' },
  { value: 'SHIPMENT_NUMBER', label: 'Shipment #' },
  { value: 'SUPPLIER_ID', label: 'Supplier ID' },
  { value: 'TRACKING_NUMBER', label: 'Tracking number' },
];

export const defaultDeleteMessage =
  'Are you sure you want to delete this item?';

export const brandDeleteMessage =
  'All related categories and products will also be deleted too! Do you want to delete it anyway?';

export const categoryDeleteMessage =
  'All related products will also be deleted! Do you want to delete it anyway?';

export const productDeleteMessage =
  'Are you sure you want to delete this product?';

export const variationDeleteMessage =
  'Are you sure you want to delete this variation?';

export const promocodeDeleteMessage =
  'Are you sure you want to delete this promocode?';

export const promoTypeDefaultOptions = [
  { value: PromoTypeEnum.FIXED_AMOUNT, label: 'Fixed amount' },
  { value: PromoTypeEnum.FREE_DELIVERY, label: 'Free delivery' },
  { value: PromoTypeEnum.PERCENTAGE, label: 'Percentage' },
];

export const activeStatusDefaultOptions = [
  { value: ActiveStatusEnum.Active, label: 'Active' },
  { value: ActiveStatusEnum.Inactive, label: 'Inactive' },
];

export const shippingAmount = 7.99;

export const destinationDefaultOptions = [
  {
    value: GoogleMerchantsDestinations.BUY_ON_GOOGLE_LISTINGS,
    label: 'Buy on Google listings',
  },
  { value: GoogleMerchantsDestinations.DISPLAY_ADS, label: 'Display ads' },
  { value: GoogleMerchantsDestinations.FREE_LISTINGS, label: 'Free listings' },
  {
    value: GoogleMerchantsDestinations.FREE_LOCAL_LISTINGS,
    label: 'Free local listings',
  },
  {
    value: GoogleMerchantsDestinations.LOCAL_INVENTORY_ADS,
    label: 'Local inventory ads',
  },
  { value: GoogleMerchantsDestinations.SHOPPING_ADS, label: 'Shopping ads' },
  {
    value: GoogleMerchantsDestinations.YOUTUBE_SHOPPING,
    label: 'YouTube Shopping',
  },
];

export const priorityOptions = [
  { value: 1, label: 'Lowest' },
  { value: 2, label: 'Low' },
  { value: 3, label: 'Medium' },
  { value: 4, label: 'High' },
  { value: 5, label: 'Highest' },
];

export const variationStatusOptions = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'DRAFT', label: 'DRAFT' },
  { value: 'ARCHIVE', label: 'ARCHIVE' },
];

export const employeeRoleOptions = [
  { value: EEmployeeRoles.ADMIN, label: 'Admin' },
  { value: EEmployeeRoles.SUPPLIER, label: 'Supplier' },
  { value: EEmployeeRoles.MANAGER, label: 'Manager' },
];

export const orderStatusCategoryColors = {
  todo: '#D9D9D9',
  payment: '#ADDA81',
  refund: '#C8453C',
  purchase: '#B6D7A8',
  sending: 'blue',
  delivery: '#BA55D3',
  return: 'gold',
  dispute: '#F6B26B',
  reshipment: '#8D8D8D',
  supplier: 'peru',
};

export const dateFilterParams = [
  { label: 'New ones first', value: 'DESC' },
  { label: 'Old ones first', value: 'ASC' },
];
