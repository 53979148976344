import { useState } from 'react';

import { DefaultOptionType } from 'antd/es/select';
import jwtDecode from 'jwt-decode';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { createSearchParams } from 'react-router-dom';
import { toast, ToastOptions } from 'react-toastify';
import { TParams } from 'store/filters-model';

import {
  IBrand,
  ICurrentUser,
  IField,
  IProductKit,
  ISubcategory,
  IUserData,
  IVariation,
  TOption,
} from './types';
import { TSlideValue } from './types/slider';

export const getObservableTableCellValue = <T>(
  dataIndex: string | number | readonly (string | number)[],
  record: T | Record<string, any>,
) => {
  const recordValue = !!Array.isArray(dataIndex)
    ? getObjectPropertyByPath(record, dataIndex.join('.'), '-')
    : record[dataIndex as keyof typeof record];

  const observedValue = !!Array.isArray(dataIndex)
    ? recordValue
    : record[dataIndex as keyof typeof record];

  return observedValue;
};

export const sortByField =
  <T>(field: keyof T, type?: string) =>
  (prev: T, next: T) => {
    return prev[field] > next[field] ? 1 : -1;
  };

export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export const sortByDate =
  <T extends { [K in keyof T]: T[K] extends Date ? K : never }>(
    field: keyof T,
  ) =>
  (prev: T, next: T) => {
    const prevField = prev[field];
    const nextField = next[field];

    if (isString(prevField) && isString(nextField)) {
      const newDatePrev = new Date(prevField);
      const newDateNext = new Date(nextField);
      return newDatePrev.getTime() > newDateNext.getTime() ? 1 : -1;
    }
    return -1;
  };

/**
 * @deprecated please use mockIdKeyGen for proper naming
 */
export const orderLinkKeyGen = () => {
  return Math.random()
    .toString(36)
    .substring(2, 4 + 2);
};

export const mockIdKeyGen = () =>
  Math.random()
    .toString(36)
    .substring(2, 4 + 2);

export const mockNumberIdKeyGen = () => Math.floor(Math.random() * 500);

export const roundToHundredths = (num: number) =>
  num ? Number(num.toFixed(2)) : 0;

export const dollars2cents = (dollars: number) =>
  Math.round(roundToHundredths(dollars) * 100);

export const cents2dollars = (cents: number) => roundToHundredths(cents / 100);

export const calculateDiscount = (price: number, discount: number) =>
  cents2dollars(Math.round(dollars2cents(price) * ((100 - discount) / 100)));

export const useModal = (
  isShow?: boolean,
): [boolean, () => void, () => void] => {
  const [isModalOpen, setModalOpen] = useState(!!isShow);
  return [isModalOpen, () => setModalOpen(true), () => setModalOpen(false)];
};

export const notify = (
  text: string | React.ReactNode,
  type: ToastOptions['type'] = 'success',
) =>
  toast(text, {
    type,
  });

export const getObjectPropertyByPath = (
  obj: Record<any, any> | any,
  path: string,
  defaultValue: any,
) => path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), obj);

export const serializeEntityOptions = (
  options: IBrand[] | ISubcategory[],
): DefaultOptionType[] => {
  return options.map((option) => ({
    value: option.id,
    label: option.title,
  }));
};

export const getNestedProperty = (
  record: Record<string, any>,
  keys: string[],
) => {
  return keys.reduce((rec, key) => {
    if (typeof rec === 'object') {
      return rec[key];
    }

    return rec;
  }, record);
};

export const parseDate = (date: string): string | undefined => {
  if (!date) return;

  if (typeof date !== 'string') {
    console.error('Invalid data type');
  }

  const newDate = new Date(date);

  if (isNaN(newDate.getTime())) {
    console.error('Invalid Date');
  }

  if (newDate.toString() === 'Invalid Date') {
    console.error('Invalid Date');
  }

  const day = newDate.getDate().toString().padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear().toString();
  const hours = newDate.getHours().toString().padStart(2, '0');
  const minutes = newDate.getMinutes().toString().padStart(2, '0');
  const seconds = newDate.getSeconds().toString().padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export const parseToShortDate = (date: string): string | undefined => {
  if (!date) {
    return;
  }

  if (typeof date !== 'string') {
    console.error('Invalid data type');
  }

  const newDate = new Date(date);

  if (isNaN(newDate.getTime())) {
    console.error('Invalid Date');
  }

  if (newDate.toString() === 'Invalid Date') {
    console.error('Invalid Date');
  }

  const day = newDate.getDate().toString().padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear().toString();

  return `${day}.${month}.${year}`;
};

export const parseToLongDate = (date: string): string | undefined => {
  if (!date) {
    return;
  }

  if (typeof date !== 'string') {
    console.error('Invalid data type');
  }

  const newDate = new Date(date);

  if (isNaN(newDate.getTime())) {
    console.error('Invalid Date');
  }

  if (newDate.toString() === 'Invalid Date') {
    console.error('Invalid Date');
  }

  const day = newDate.getDate().toString().padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear().toString();
  const hours = newDate.getHours().toString().padStart(2, '0');
  const minutes = newDate.getMinutes().toString().padStart(2, '0');
  const seconds = newDate.getSeconds().toString().padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};

export const getVariationView = (params: IVariation['content']) => {
  const { color, material, type, style, size } = params;

  return Object.values({
    color: color,
    material: material,
    type: type,
    style: style,
    size: size,
  })
    .filter((value) => value)
    .join(', ');
};

export const getOrderItemsKitsIds = (
  orderItems: { productKit: IProductKit | null }[],
) => {
  const productKitIdsSet = orderItems.reduce((acc, item) => {
    if (item.productKit) {
      acc.add(item.productKit.id);
    }

    return acc;
  }, new Set<number>());

  return Array.from(productKitIdsSet);
};

export const getEllipsisText = (description: string) => {
  return description?.length > 50
    ? description.substring(0, 50) + '...'
    : description;
};

export const getUpdatedSlides = (
  submittedSlides: TSlideValue[],
  initialSlides: TSlideValue[],
) =>
  submittedSlides.filter(
    (slide, index) =>
      slide.id === initialSlides[index]?.id &&
      JSON.stringify(slide) !== JSON.stringify(initialSlides[index]),
  );

export const reduceOrderItems = (
  items: { id: number; selected?: boolean; selectedQuantity?: number }[],
) =>
  items.reduce(
    (acc, item) =>
      item.selected ? { ...acc, [item.id]: item.selectedQuantity } : acc,
    {},
  );

export const getPurchasesKitsIds = (
  purchases: { productKit: IProductKit | null }[],
) => {
  const productKitIdsSet = purchases.reduce((acc, el) => {
    if (el.productKit) {
      acc.add(el.productKit.id);
    }

    return acc;
  }, new Set<number>());

  return Array.from(productKitIdsSet);
};

export const getReturnItemsKitsIds = (
  returnItems: { orderItem: { productKit: IProductKit | null } }[],
) => {
  const productKitIdsSet = returnItems.reduce((acc, el) => {
    if (el.orderItem.productKit) {
      acc.add(el.orderItem.productKit.id);
    }

    return acc;
  }, new Set<number>());

  return Array.from(productKitIdsSet);
};

export const reduceReturnShipmentItems = (
  items: { orderItem: { id: number }; quantity: number }[],
) =>
  items.reduce(
    (acc, item) => ({ ...acc, [item.orderItem.id]: item.quantity }),
    {},
  );

export const replaceHistoryState = (link: string, params: TParams) => {
  window.history.replaceState({}, link, `?${createSearchParams(params)}`);
};

export const upperUndescoreToRegularCase = (value: string) =>
  // Make 'STATUS_PAID' looks like Status paid
  value.charAt(0).toLocaleUpperCase() +
  value.slice(1).split('_').join(' ').toLocaleLowerCase();

export const removeStatusPrefix = (str: string, statusSubstr: string) =>
  str.replace(statusSubstr, '');

export const compareFlatObjects = (
  object: Record<string | number, unknown>,
  subject: Record<string | number, unknown>,
) =>
  Object.keys({ ...object, ...subject }).every(
    (key) => object[key] === subject[key],
  );

export const compareArraysOfPrimitives = (
  a: (boolean | number | string)[],
  b: (boolean | number | string)[],
) => a.length === b.length && !a.some((element) => !b.includes(element));

export const compareArraysOfObjects = (
  initialArray: (TOption | undefined)[],
  array: (TOption | undefined)[],
) =>
  initialArray.filter((type) =>
    array?.every((item) => item?.value !== type?.value),
  );

export const removeEmptyObjProps = (obj: Record<string, any>) =>
  Object.keys(obj).reduce((acc, curr) => {
    if (!!obj[curr] || typeof obj[curr] === 'number') {
      acc[curr] = obj[curr];
    }

    return acc;
  }, {} as Record<string, any>);

export const downloadFile = (file: Blob, name: string) => {
  const fileURL = window.URL.createObjectURL(file);

  const a = document.createElement('a');
  a.href = fileURL;
  a.download = name;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const decodeJwt = <T>(jwt?: string | null): T | null => {
  try {
    return jwt ? jwtDecode(jwt) : null;
  } catch (e) {
    return null;
  }
};

export const checkCurrentUser = () => {
  const token = localStorage.getItem('token');

  if (token) {
    const decoded = decodeJwt<ICurrentUser>(token);
    const exp = decoded?.exp ?? 0;
    const isTokenExpired = exp * 1000 < Date.now();

    if (!isTokenExpired) {
      return decoded;
    }
  }

  return null;
};

export const scrollToFormError = (errors: FieldErrors<FieldValues>) => {
  const errorsKeys = Object.keys(errors);

  if (!errorsKeys.length) {
    return;
  }

  const selector = errorsKeys[0];

  const errorElement =
    document.getElementById(selector) ||
    document.getElementsByName(selector)[0] ||
    document.querySelector(`[id^='${selector}']`);

  if (errorElement) {
    errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const combineString = (
  entries: (string | number | null | undefined)[],
) => entries.filter((entry) => !!entry).join(' ');

export const getPersonalInformationFields = (userData: IUserData | null) => {
  const data = userData ?? ({} as IUserData);

  return [
    {
      name: 'firstName',
      label: 'First name',
      value: data.firstName,
    },
    {
      name: 'lastName',
      label: 'Last name',
      value: data.lastName,
    },
    {
      name: 'email',
      label: 'Email',
      value: data.email,
    },
    {
      name: 'phone',
      label: 'Phone',
      value: data.phone,
    },
  ];
};

export const getShippingAddressFields = (userData: IUserData | null) => {
  const data = userData ?? ({ shippingAddress: {} } as IUserData);

  return [
    {
      name: 'firstName',
      label: 'First name',
      value: data.shippingAddress.firstName,
    },
    {
      name: 'lastName',
      label: 'Last name',
      value: data.shippingAddress.lastName,
    },
    {
      name: 'address',
      label: 'Address',
      value: data.shippingAddress.address,
    },
    {
      name: 'address2',
      label: 'Apartment',
      value: data.shippingAddress.address2,
    },
    {
      name: 'city',
      label: 'City',
      value: data.shippingAddress.city,
    },
    {
      name: 'state',
      label: 'State',
      value: data.shippingAddress.state,
    },
    {
      name: 'postalCode',
      label: 'Postal code',
      value: data.shippingAddress.postalCode,
    },
    {
      name: 'country',
      label: 'Country',
      value: data.shippingAddress.country,
    },
    {
      name: 'phone',
      label: 'Phone',
      value: data.shippingAddress.phone,
    },
  ];
};

export const getBillingAddressFields = (userData: IUserData | null) => {
  const data = userData ?? ({ billingAddress: {} } as IUserData);

  return [
    {
      name: 'companyName',
      label: 'Company name',
      value: data.billingAddress.companyName,
    },
    {
      name: 'firstName',
      label: 'First name',
      value: data.billingAddress.firstName,
    },
    {
      name: 'lastName',
      label: 'Last name',
      value: data.billingAddress.lastName,
    },
    {
      name: 'address',
      label: 'Address',
      value: data.billingAddress.address,
    },
    {
      name: 'address2',
      label: 'Apartment',
      value: data.billingAddress.address2,
    },
    {
      name: 'city',
      label: 'City',
      value: data.billingAddress.city,
    },
    {
      name: 'state',
      label: 'State',
      value: data.billingAddress.state,
    },
    {
      name: 'postalCode',
      label: 'Postal code',
      value: data.billingAddress.postalCode,
    },
    {
      name: 'country',
      label: 'Country',
      value: data.billingAddress.country,
    },
    {
      name: 'phone',
      label: 'Phone',
      value: data.billingAddress.phone,
    },
  ];
};

export const getInitialValues = (fields: IField[]) => {
  const initialValues: { [key: string]: string | undefined } = {};

  fields.forEach((field) => {
    if (field.disabled) {
      return;
    }

    initialValues[field.name] = field.value ?? '';
  });

  return initialValues;
};
