import { FC, Fragment } from 'react';

import { Button, Spin, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { PurchasesViewModel } from 'pages/NewOrder/view-models/purchases-view-model';

import { AddLinksModal } from './AddLinksModal';
import { AddPurchasesModal } from './AddPurchaseModal';
import { ConfirmPurchasesModal } from './ConfirmPurchasesModal';
import { EditPurchasesModal } from './EditPurchasesModal';
import { Purchase } from './Purchase';

import styles from './purchases.module.scss';

export interface IPurchasesProps {
  model: PurchasesViewModel | null;
}

export const Purchases: FC<IPurchasesProps> = observer(({ model }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <Typography.Title level={4} className={styles.title}>
        Purchases
      </Typography.Title>
      <Button
        disabled={!model || model?.disabled}
        onClick={model?.onEditModalOpen}
      >
        Edit
      </Button>
    </div>
    <div className={styles.content}>
      {!model ? (
        <Spin className={styles.spin} />
      ) : (
        <>
          {model.purchases.map((purchase) => (
            <Fragment key={purchase.sku}>
              <Purchase
                productId={purchase.variation.productId}
                sku={purchase.sku}
                quantity={purchase.quantity}
                price={purchase.dollarPrice}
                kit={purchase.productKit?.id}
                title={purchase.title}
                variationView={purchase.variationView}
                photo={purchase.photo}
                links={purchase.orderLinks}
                disabled={purchase.disabled}
                onAddLinkClick={purchase.onAddLinksModalOpen}
              />
              <AddLinksModal
                open={purchase.isAddLinksModalOpen}
                isLoading={purchase.isLoading}
                orderLinks={purchase.orderLinks}
                onOk={purchase.onAddLinksModalSubmit}
                onCancel={purchase.onAddLinksModalClose}
                onSync={purchase.onOrderLinkSync}
              />
            </Fragment>
          ))}
          <EditPurchasesModal
            open={model.isEditModalOpen}
            purchases={model.purchases}
            onOk={model.onEditModalSubmit}
            onCancel={model.onEditModalClose}
            onAddModalOpen={model.onAddModalOpen}
            onKitRemove={model.onKitPurchasesRemove}
          />
          <AddPurchasesModal
            open={model.isAddModalOpen}
            isLoading={model.isLoading}
            onOk={model.onAddModalSubmit}
            onCancel={model.onAddModalClose}
          />
          <ConfirmPurchasesModal
            open={model.isConfirmModalOpen}
            purchases={model.purchases}
            isLoading={model.isLoading}
            responsibleOptions={
              model.root?.root?.root?.responsibleOptions || []
            }
            reasonOptions={model.root?.root?.root?.defaultReasonOptions || []}
            onOk={model.onConfirmModalSubmit}
            onCancel={model.onConfirmModalClose}
          />
        </>
      )}
    </div>
  </div>
));
