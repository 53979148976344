import { FC } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { ShipmentsViewModel } from 'pages/NewOrder/view-models/shipments-view-model';

import { AddShipmentModal } from './AddShipmentModal';
import { Shipment } from './Shipment';

import styles from './shipments.module.scss';

interface IShipmentsProps {
  model: ShipmentsViewModel | null;
}

export const Shipments: FC<IShipmentsProps> = observer(({ model }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <Typography.Title level={4} className={styles.title}>
        Shipments
      </Typography.Title>
      <Button
        icon={<PlusOutlined />}
        disabled={!model || model?.disabled}
        onClick={model?.onAddShipmentModalOpen}
      >
        Add new shipment
      </Button>
      {!!model && (
        <AddShipmentModal
          open={model.isAddShipmentModalOpen}
          purchases={model.root?.purchasesViewModel?.purchases ?? []}
          supplierOptions={model.root?.root?.root?.supplierOptions ?? []}
          isLoading={model.isLoading}
          onOk={model.onShipmentCreate}
          onCancel={model.onAddShipmentModalClose}
        />
      )}
    </div>
    <div className={styles.content}>
      {!model ? (
        <Spin className={styles.spin} />
      ) : (
        <>
          {model.shipments.map((shipment) => (
            <Shipment
              key={shipment.id}
              model={shipment}
              disabled={model.disabled}
              onShipmentDelete={model.onShipmentDelete}
            />
          ))}
        </>
      )}
    </div>
  </div>
));
