import { FC } from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import cn from 'clsx';
import { ConfirmButton } from 'components/ConfirmButton';
import { observer } from 'mobx-react-lite';
import { ShipmentViewModel } from 'pages/NewOrder/view-models/shipment-view-model';

import { EditShipmentModal } from '../EditShipmentModal';

import { ShipmentDetails } from './ShipmentDetails';

import styles from './shipment.module.scss';

export interface IShipment {
  model: ShipmentViewModel;
  disabled: boolean;
  onShipmentDelete: (id: number) => Promise<void>;
}

export const Shipment: FC<IShipment> = observer(
  ({ model, disabled, onShipmentDelete }) => (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.column}>
          <Button
            size="small"
            shape="circle"
            icon={<RightOutlined />}
            className={cn({ [styles.rotated]: model.isExpanded })}
            onClick={model.onToggleExpanded}
          />
          <div className={styles.title}>
            <Typography.Text strong>Shipment # {model.id}</Typography.Text>
            {model.trackingStatus && (
              <Typography.Text className={styles.status}>
                {model.trackingStatus}
              </Typography.Text>
            )}
          </div>
        </div>
        <div className={styles.images}>
          {model.itemsModelsView.map((item) => (
            <img
              key={item.id}
              src={item.photo.url}
              alt={item.photo.key}
              className={styles.image}
            />
          ))}
        </div>
        <div className={styles.column}>
          <Typography.Text strong>Date: </Typography.Text>
          <Typography.Text>{model.createdAtView}</Typography.Text>
        </div>
        <div className={styles.column}>
          <Button onClick={model.onEditModalOpen}>Items</Button>
          <ConfirmButton
            confirm={{ title: 'This action cannot be undone, are you sure?' }}
            isLoading={model.isLoading}
            disabled={disabled}
            onConfirm={() => model.handleShipmentDelete(onShipmentDelete)}
          >
            Delete
          </ConfirmButton>
        </div>
      </div>
      {model.isExpanded && (
        <div className={styles.details}>
          <ShipmentDetails model={model} disabled={disabled} />
        </div>
      )}
      <EditShipmentModal
        open={model.isEditModalOpen}
        shipmentItems={model.items}
        shipmentId={model.id}
        orderItems={model.root?.root?.order?.items ?? []}
        disabled={disabled}
        isLoading={model.isLoading}
        onOk={model.onShipmentItemsUpdate}
        onCancel={model.onEditModalClose}
      />
    </div>
  ),
);
