import { FC } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { ReturnsViewModel } from 'pages/NewOrder/view-models/returns-view-model';

import { AddReturnModal } from './AddReturnModal';
import { Return } from './Return';

import styles from './returns.module.scss';

interface IReturnsProps {
  model: ReturnsViewModel | null;
}

export const Returns: FC<IReturnsProps> = observer(({ model }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <Typography.Title level={4} className={styles.title}>
        Returns
      </Typography.Title>
      <Button
        icon={<PlusOutlined />}
        disabled={!model || model?.disabled}
        onClick={model?.onAddReturnModalOpen}
      >
        Add new return
      </Button>
      {!!model && (
        <AddReturnModal
          open={model.isAddReturnModalOpen}
          purchases={model.root?.purchasesViewModel?.purchases ?? []}
          itemStats={model.root?.order?.itemStats ?? []}
          supplierOptions={model.root?.root?.root?.supplierOptions ?? []}
          responsibleOptions={
            model.root?.root?.root?.defaultResponsibleOptions ?? []
          }
          reasonOptions={
            model.root?.root?.root?.defaultReturnReasonOptions ?? []
          }
          isLoading={model.isLoading}
          onOk={model.onReturnCreate}
          onCancel={model.onAddReturnModalClose}
        />
      )}
    </div>
    <div className={styles.content}>
      {!model ? (
        <Spin className={styles.spin} />
      ) : (
        <>
          {model.returnShipments.map((returnShipment) => (
            <Return
              key={returnShipment.id}
              model={returnShipment}
              disabled={model.disabled}
              onDelete={model.onReturnDelete}
            />
          ))}
        </>
      )}
    </div>
  </div>
));
