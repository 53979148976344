import { FC } from 'react';

import { Spin, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { StatusesViewModel } from 'pages/NewOrder/view-models/statuses-view-model';

import { Status } from './Status';

import styles from './statuses.module.scss';

interface IStatusesProps {
  model: StatusesViewModel | null;
}

export const Statuses: FC<IStatusesProps> = observer(({ model }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <Typography.Title level={4} className={styles.title}>
        Statuses
      </Typography.Title>
    </div>
    <div className={styles.content}>
      {!model ? (
        <Spin className={styles.spin} />
      ) : (
        <div className={styles.statuses}>
          <Status
            label="TO DO"
            field={{
              type: 'select',
              props: {
                value: model.todo,
                mode: 'multiple',
                placeholder: 'Select event',
                options: model.todoOptions,
                disabled: model.root?.order?.disabled,
                loading: model.isLoading,
                onChange: (e: string[]) => model.onStatusChange(e, 'todo'),
              },
            }}
          />
          <Status
            label="Reshipments"
            values={model.reshipmentsView}
            field={{ type: 'multiple' }}
          />
          <Status
            label="Refund"
            values={model.refundView}
            field={{ type: 'multiple' }}
          />
          <Status
            label="Supplier"
            values={model.supplierView}
            field={{ type: 'multiple' }}
          />
          <Status label="Return" value={model.returnView} />
          <Status
            label="Dispute"
            field={{
              type: 'select',
              props: {
                defaultValue: model.disputeView,
                placeholder: 'Select dispute',
                options: model.disputeOptions,
                disabled: model.root?.order?.isCancelled,
                loading: model.isLoading,
                onChange: (e: string[]) =>
                  model.onStatusChange(e, 'disputeStatus'),
              },
            }}
          />
        </div>
      )}
    </div>
  </div>
));
