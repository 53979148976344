import { FC } from 'react';

import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import cn from 'clsx';
import { useSelectDynamicOptions } from 'common/hook/useSelectDynamicOptions';
import { TDynamicOptions } from 'common/types';
import { Controller, useFormContext } from 'react-hook-form';

import styles from '../form.module.scss';

export interface IFormSelectProps extends SelectProps {
  name: string;
  label: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  className?: string;
  selectClassName?: string;
  zIndex?: number;
}

interface IDynamicFormSelectProps extends IFormSelectProps {
  staticOptions?: DefaultOptionType[];
  getOptions: () => TDynamicOptions;
}

export const FormSelect: FC<IFormSelectProps> = ({
  name,
  label,
  labelPosition = 'top',
  required,
  className,
  selectClassName,
  options,
  showSearch,
  zIndex = 998,
  onChange,
  ...props
}) => {
  const { control } = useFormContext();

  const filterOption = showSearch
    ? (input: string, option: DefaultOptionType) =>
        (option?.label ?? '')
          .toString()
          .toLowerCase()
          .includes(input.toLowerCase())
    : undefined;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange: internalChange, value, name },
        fieldState: { error },
      }) => {
        const handleSelectChange = (
          value: string,
          option: DefaultOptionType | DefaultOptionType[],
        ) => {
          internalChange(value);
          onChange?.(value, option);
        };

        return (
          <div
            className={cn(
              styles.container,
              styles.inputWrapper,
              styles[`label-${labelPosition}`],
              className,
            )}
          >
            <label htmlFor={name} className={styles.label}>
              {label}
              {required && <span className={styles.required}>*</span>}
            </label>
            <Select
              id={name}
              onChange={handleSelectChange}
              value={value}
              dropdownStyle={{ zIndex: zIndex }}
              className={cn(selectClassName, styles.inputWidth)}
              status={!!error ? 'error' : ''}
              options={options}
              showSearch={showSearch}
              filterOption={filterOption}
              {...props}
            />
            {!!error && <div className={styles.error}>{error?.message}</div>}
          </div>
        );
      }}
    />
  );
};

// TODO: make common select component with dynamic options and use it inside FormSelect
export const DynamicFormSelect: FC<IDynamicFormSelectProps> = ({
  staticOptions,
  getOptions,
  ...props
}) => {
  const [isLoading, options] = useSelectDynamicOptions({
    staticOptions: staticOptions,
    fetchOptions: getOptions,
  });

  return <FormSelect loading={isLoading} options={options} {...props} />;
};
