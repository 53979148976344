import { AxiosResponse } from 'axios';

import { ILogin, ILoginParams } from '../common/types/current-user';

import { $host } from './index';

export const login = async (values: ILoginParams): Promise<string> => {
  const { data } = await $host.post<AxiosResponse<ILogin>>(
    'admin/auth/log-in',
    values,
  );

  if (typeof data.data.accessToken === 'string') {
    localStorage.setItem('token', data.data.accessToken);
    $host.defaults.headers.Authorization = `Bearer ${data.data.accessToken}`;
  }

  return data.data.accessToken;
};

export const logout = async (): Promise<string> => {
  const { data } = await $host.get<AxiosResponse<string>>('admin/auth/log-out');

  return data.data;
};
