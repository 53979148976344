import {
  createProduct,
  deleteProductPhotos,
  editFeedDestinations,
  editProduct,
  getAttributes,
  getProductAutocompleteByType,
  updateAllVariations,
} from 'http/productApi';
import {
  createVariationByProductId,
  deleteById as deleteVariation,
} from 'http/variationApi';

import { BaseOptionType } from 'antd/es/select';
import {
  IBrand,
  ICreateProduct,
  ISubcategory,
  IAutocompleteTypeParams,
  IAutocompleteSubcategoryTypeParams,
  IServerError,
} from 'common/types';
import { ProductAutocompleteTypeEnum } from 'common/types/enums';
import {
  dollars2cents,
  notify,
  removeEmptyObjProps,
  serializeEntityOptions,
} from 'common/utils';
import { computed, makeAutoObservable, observable } from 'mobx';
import { ProductModel } from 'store/product-model';

function serializeOptions(options: string[]) {
  return options
    .filter((value) => !!value)
    .map((option) => ({
      value: option,
    }));
}

export class ProductControllerViewModel {
  _loading = false;
  _materialAutocomplete: BaseOptionType[] = [];
  _typeAutocomplete: BaseOptionType[] = [];
  _brands: IBrand[] = [];
  _brandId = 0;
  _subcategories: ISubcategory[] = [];
  _product: ProductModel;

  get loading() {
    return this._loading || this._product.loading;
  }

  set loading(loading: boolean) {
    this._loading = loading;
  }

  get product() {
    return this._product;
  }

  set materialAutocomplete(options: BaseOptionType[]) {
    this._materialAutocomplete = options;
  }

  get materialAutocomplete() {
    return this._materialAutocomplete;
  }

  set typeAutocomplete(options: BaseOptionType[]) {
    this._typeAutocomplete = options;
  }

  get typeAutocomplete() {
    return this._typeAutocomplete;
  }

  get brandOptions() {
    return serializeEntityOptions(this._brands);
  }

  get subcategoryOptions() {
    return serializeEntityOptions(
      this._subcategories.filter(
        (subcategory) => subcategory.brandId === this._brandId,
      ),
    );
  }

  set selectedBrand(brandId: number) {
    this._brandId = brandId;
  }

  get initialFormValues() {
    return {
      variations: this.product.variationsObject,
      markdownSections: this.product.markdownSections,
      title: this.product.title,
      description: this.product.product.description,
      placement: this.product.product.placement,
      type: this.product.product.type,
      material: this.product.product.material,
      color: this.product.product.color,
      sku: this.product.product.sku,
      barcode: this.product.product.barcode,
      order_link: this.product.product.order_link,
      googleMerchantsDestinations:
        this.product.product.googleMerchantsDestinations,
      price: this.product.price,
      discount: this.product.product.discount,
      metaTitle: this.product.product.metaTitle,
      metaDescription: this.product.product.metaDescription,
      photos: this.product.product.photos ?? [],
      brandId: this.product.subcategory.brandId || undefined,
      subcategoryId: this.product.subcategory.id || undefined,
      status: this.product.product.status,
    };
  }

  fetchBrands = async () => {
    try {
      const brands = await getProductAutocompleteByType(
        ProductAutocompleteTypeEnum.brands,
      );

      this._brands = brands.map((brand: IAutocompleteTypeParams) => {
        return { ...brand, title: brand.value };
      });
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    }
  };

  fetchSubcategories = async () => {
    try {
      const subcategories = await getProductAutocompleteByType(
        ProductAutocompleteTypeEnum.subcategories,
      );

      this._subcategories = subcategories.map(
        (subcategory: IAutocompleteSubcategoryTypeParams) => {
          return { ...subcategory, title: subcategory.value };
        },
      );
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    }
  };

  fetchAutocompleOptions = async () => {
    try {
      const { material, type } = await getAttributes();

      this.materialAutocomplete = serializeOptions(material);
      this.typeAutocomplete = serializeOptions(type);
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    }
  };

  fetchData = async () => {
    this.loading = true;

    try {
      await Promise.all([
        this.fetchAutocompleOptions(),
        this.fetchBrands(),
        this.fetchSubcategories(),
      ]);
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  removeVariation = async (id: number) => {
    try {
      const response = await Promise.all([
        await deleteVariation(id),
        this.product.fetchProduct(this.product.id, true),
      ]);

      if (response) {
        return true;
      }
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    }
  };

  updateProduct = async (values: ICreateProduct) => {
    const existingPhotos = values.photos.reduce(
      (ids, photo) => (!photo.file ? [...ids, photo.id] : ids),
      [],
    );

    const existingPhotosSet = new Set(existingPhotos);

    const deletedDBImageIds = this._product.product?.photos?.reduce(
      (ids, photo) => {
        if (!existingPhotosSet.has(photo.id)) {
          return [...ids, photo.id];
        }

        return ids;
      },
      [],
    );

    if (deletedDBImageIds?.length) {
      try {
        await deleteProductPhotos(deletedDBImageIds);
      } catch (error) {
        throw error;
      }
    }

    const productValues = values.photos[0]
      ? {
          ...values,
          mainPhotoId: String(values.photos[0].id),
        }
      : values;

    const serializedProduct = this._product.serializeProduct(productValues);

    try {
      await editProduct(this._product.id, serializedProduct);
      await editFeedDestinations(this._product.id, {
        googleMerchantsDestinations: productValues.googleMerchantsDestinations,
      });
    } catch (error) {
      throw error;
    }
  };

  createProduct = async (values: ICreateProduct) => {
    const serializedProduct = this._product.serializeProduct(values);

    const variations = Object.entries(values.variations).reduce(
      (variationObject, [key, values]) => {
        if (values.length) {
          return {
            ...variationObject,
            [key]: values,
          };
        }

        return variationObject;
      },
      {},
    );

    try {
      const product = await createProduct(serializedProduct);

      if (Object.keys(variations).length) {
        try {
          await createVariationByProductId(product.id, variations);
        } catch (e) {
          notify(`Error, reason:${e}`, 'error');
        }
      }
    } catch (error) {
      throw error;
    }
  };

  manageProduct = async (isUpdate: boolean, values: ICreateProduct) => {
    try {
      if (isUpdate) {
        await this.updateProduct(values);
      } else {
        await this.createProduct(values);
      }

      notify(
        <div>
          Product <strong>{values.title}</strong> was{' '}
          {isUpdate ? 'updated' : 'created'} succesfully!
        </div>,
      );
    } catch (error) {
      const messages = (error as unknown as IServerError).response.data.message;

      if (messages && Array.isArray(messages)) {
        messages.forEach((message) => notify(message, 'error'));
      } else if (typeof messages === 'string') {
        notify(messages, 'error');
      } else {
        notify(`Failed to ${isUpdate ? 'update' : 'create'} Product`, 'error');
      }

      throw error;
    }
  };

  changeAllVariations = async (
    id: number,
    params: Record<string, string | number | undefined>,
  ) => {
    try {
      const sanitizedParams = removeEmptyObjProps(params);

      if (sanitizedParams.price) {
        sanitizedParams.price = dollars2cents(sanitizedParams.price);
      }

      await updateAllVariations(id, sanitizedParams);
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    }
  };

  constructor(id: number) {
    this.fetchData();

    this._product = new ProductModel(id);

    makeAutoObservable(this, {
      _loading: observable,
      _product: observable,
      _materialAutocomplete: observable,
      _typeAutocomplete: observable,
      _subcategories: observable,
      _brands: observable,
      _brandId: observable,
      typeAutocomplete: computed,
      materialAutocomplete: computed,
      subcategoryOptions: computed,
      brandOptions: computed,
      loading: computed,
      product: computed,
    });
  }
}
